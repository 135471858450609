<template>
  <div class="wx-login">
    <div v-if="!status">
      <div style="position: relative;width: 200px;height: 200px;margin: 0 auto;">
        <Spin fix v-if="!teacherWxCode"></Spin>
        <img v-else :src="teacherWxCode" width="200" height="200">
        <div class="refreshBj" v-if="refresh">
          <Icon type="md-refresh" :size="30" color="#2d8cf0" class="refreshBj-icon" @click="getImage" />
        </div>
      </div>
    </div>
<!--    <div v-else>-->
<!--      <div class="top" >-->
<!--        <div class="back">-->
<!--          <span>重新扫码</span><Button @click="back">&lt; 返回</Button>-->
<!--        </div>-->
<!--        <div class="info">-->
<!--          &lt;!&ndash;          <img src="../../../assets/images/login/logo-min.png">&ndash;&gt;-->
<!--          您已扫码，请完善您的信息!-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="cont">-->
<!--        <TeacherWxForm :appid="appid" :wxOpenId="wxOpenId" :unionid="unionid" :isCompany="isCompany" @success="loginSuccess"></TeacherWxForm>-->
<!--      </div>-->

<!--    </div>-->
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import TeacherWxForm from "@/views/guest/components/teacherWxForm";
import {tokenName} from '@/router/init.js'
import util from '@/utils/tools.js'
export default {
  name: "wxLogin",
  data(){
    return{
      tabList:[
        {
          name:'login_wx_create',
          id:1
        },
        {
          name:'login_wx_binding',
          id:2
        }
      ],
      curTabId:1,
      code:'',
      status:false,
      wxOpenId:'',
      bindAccount:1,
      unionid:'',
      teacherWxCode:'',
      scene:'',
      timer:null,
      appid:'',
      sec:0,
      refresh:false,
    }
  },
  components:{
    TeacherWxForm
  },
  watch:{
    sec(newV){
      if(newV >= 60){
        clearInterval(this.timer);
        // this.sec = 0;
        this.refresh = true;
      }
    }
  },
  created(){
    this.getImage();
  },
  props:{
    isCompany:{
      default:false,
      type:Boolean
    }
  },
  methods:{
    changeTab(data){
      this.curTabId = data.id;
    },
    // back(){
    //   this.status = false;
    //   this.getImage();
    // },
    changeBindAccount(){
      if(this.bindAccount == 1){
        this.bindAccount = 2;
      }else{
        this.bindAccount = 1;
      }
    },
    getImage(){ //获取二维码图片

      this.api.index.WeixinServerQrShow().then((res)=>{
        this.teacherWxCode = res.showqrcode;
        this.scene = res.scene;
        this.appid = res.appid;
        this.refresh = false;
        this.WeixinServerQrLogin();
        // clearInterval(this.timer);
        // this.timer = setInterval(()=>{
        //   this.WeixinServerQrLogin();
        //   this.sec++;
        // },1000)
      })
    },
    WeixinServerQrLogin(){
      let params = {
        scene:this.scene
      };
      if(this.$route.params.id){
        params.invitation_token = this.$route.params.id
      }
      this.api.index.WeixinServerQrLogin(params).then((res)=>{

        if(res.id){
          clearTimeout(this.timer);
          if(res.id == '0'){
            console.log('1111')
            this.status = true;
            this.wxOpenId = res.openid;
            this.unionid = res.unionid;

            this.$emit('wxSuccess',{
              appid:this.appid,
              wxOpenId: res.openid,
              unionid:res.unionid
            })
          }else{

            this.$store.commit('login', {
              accessToken: res.access_token,
              // userName: this.form.mobile
            });

            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);

            //是否登录的标志
            // Cookies.set('liteAccessToken', res.access_token);
            //添加ai问答token
            let domain = util.getDomain();
            console.log(this.constant,'this.constant')

            Cookies.set(this.constant.tokenName, res.access_token,{domain});
            // util.setCookie('liteLoginAccount', this.form.mobile, 30);

            this.$store.commit('setLoginStatus', true);
            this.$emit('success');
          }
        }else{
          if(this.sec <= 60){
            clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
              this.WeixinServerQrLogin();
              this.sec++;
            },1000)
          }else{
            this.sec = 0;
          }

        }
      }).catch((err)=>{
        if(this.sec <= 60){
          clearTimeout(this.timer);
          this.timer = setTimeout(()=>{
            this.WeixinServerQrLogin();
            this.sec++;
          },1000)
        }else{
          this.sec = 0;
        }
      })
    },
    // loginSuccess(){
    //   this.$emit('success');
    // },
    toRegisterPage(){
      this.$router.push({
        name: 'register',
      });
    },
    toRegisterSitePage(){
      this.$router.push({
        name: 'registersite',
      });
    },
    toForgetPasswordPage () {
      this.$router.push({
        name: 'forget-password',
      });
    },
  },
  beforeDestroy(){
    clearInterval(this.timer);
    clearTimeout(this.timer);
  }
}
</script>

<style scoped lang="scss">
.wx-login{
  .top{
    margin-top: 20px;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 70px;
    font-size: 15px;

    .info{
      display: flex;
      align-items: center;
      >img{
        margin-right: 10px;
        width: 80px;
        height: 80px;
        border-radius: 40px;
      }

    }
    .back{
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      >span{
        padding-right: 10px;
      }
    }
  }
  .wx-login-name{
    font-size: 14px;
    margin-bottom: 40px;
  }
  .login-tab{
    width: 80%;
    margin: 20px auto;
    border:1px solid #CCCCCC;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    line-height: 48px;
    overflow: hidden;
    >div{
      flex: 1;
      border-right: 1px solid #cccccc;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
    }
    >div:last-child{
      border-right: none;
    }
    >div.active{
      background-color: #3399ff;
      color:#FFFFFF;
    }
  }
  .cont{
    margin: 0 auto;
    width: 80%;
    .jump{
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.refreshBj{
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  text-align: center;
  line-height: 200px;
  background-color: rgba(0,0,0,0.7);
  .refreshBj-icon{
    cursor: pointer;
  }
}
</style>
