<template>
  <div>
    <p style="font-size: 16px;color:#333333;margin-top: 10px;font-weight: bold;" v-if="invitationToken">{{inviteInfo.user.nickname}}邀请您加入{{ form.company }}<span v-if="inviteInfo.group">的{{inviteInfo.group.name}}班</span></p>
    <Form ref="registerForm" :model="form" :rules="rules" style="margin-top: 25px">
      <FormItem v-if="!invitationToken">
        <div class="select-identity">
          <p class="select-identity-name">请选择你的身份:</p>
          <div class="select-identity-list">
            <div v-for="item in identityList" :key="item.id" @click="selectIdentity(item)">
              <img v-if="curIdentity == item.id" src="../../../assets/images/new_icon/xzdanxuan.png" width="16" height="16" />
              <img v-else src="../../../assets/images/new_icon/wxdanxuan.png" width="16" height="16" />

              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </FormItem>
<!--      <FormItem prop="company" v-if="isCompany">-->
<!--        <i-input v-model="form.company" :disabled="companyStatus"class="borerB" clearable size="large" placeholder="请输入组织名称">-->
<!--        </i-input>-->
<!--      </FormItem>-->
<!--      <FormItem prop="realname" v-if="curIdentity == 1">-->
<!--        <i-input v-model="form.realname" class="borerB" clearable size="large" placeholder="请输入您的真实姓名">-->
<!--        </i-input>-->
<!--      </FormItem>-->
      <FormItem prop="nickname">
        <i-input v-model="form.nickname" class="borerB" clearable size="large" placeholder="请输入您的姓名">
        </i-input>
      </FormItem>
      <FormItem prop="mobile">
        <i-input v-model="form.mobile" :maxlength="11"  class="borerB" clearable size="large" :placeholder="$t('register_input_mobile')">
        </i-input>
      </FormItem>
      <!--    <FormItem prop="captcha">-->
      <!--      <Row>-->
      <!--        <i-col span="18">-->
      <!--          <i-input clearable v-model="form.captcha"  class="borerB" :maxlength="4" size="large"-->
      <!--                   :placeholder="$t('register_input_captcha')">-->
      <!--          </i-input>-->
      <!--        </i-col>-->
      <!--        <i-col span="5" push="1">-->
      <!--          <div @click="getCaptcha" style="height: 36px;text-align:center;border-radius: 4px;cursor:pointer;;">-->
      <!--            <img :src="captchaUrl" alt="" style="display:inline-block;height: 36px">-->
      <!--          </div>-->
      <!--        </i-col>-->
      <!--      </Row>-->
      <!--    </FormItem>-->
      <FormItem prop="mobileCode">
        <Row>
          <i-col span="18">
            <i-input clearable v-model="form.mobileCode"  class="borerB" :maxlength="4" size="large"
                     :placeholder="$t('register_input_mobile_code')">
            </i-input>
          </i-col>
          <i-col span="5" push="1">
            <div class="get-code" @click="getMobileCode" v-if="mobileCodeStatus">{{ $t('login_get_code') }}</div>
            <div class="get-code-not" v-else>{{sec}}{{$t('login_mobild_code_unit')}}{{ $t('login_mobild_code_again') }}</div>
          </i-col>
        </Row>
      </FormItem>

      <FormItem prop="company"  v-if="curIdentity == '3' && !invitationToken">
        <i-input v-model="form.company" class="borerB" clearable size="large" placeholder="请输入组织全称">
        </i-input>
      </FormItem>
      <FormItem prop="joinSiteId" v-if="curIdentity != '3' && !invitationToken && curIdentity != 4">
        <!--        <i-input v-model="form.joinSiteId" :maxlength="11"  class="borerB" clearable size="large" :placeholder="$t('register_input_mobile')">-->
        <!--        </i-input>-->
        <Select v-model="form.joinSiteId"
                ref="select"
                remote
                :remote-method="remoteSite"
                class="borerB"
                size="large"
                clearable
                filterable placeholder="请搜索并选择所在的院校"
                v-if="curIdentity == '0' || curIdentity == '1'"
        >
          <Option v-for="item in siteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
        <el-select
            v-model="form.joinSiteId"
            filterable
            allow-create
            default-first-option
            placeholder="请输入所在企业名称"
            v-if="curIdentity == '2'"
            remote
            :remote-method="remoteSite"
            class="borerB"
            style="width: 100%"
        >
          <el-option
              v-for="item in siteList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            {{ item.name }}
          </el-option>
        </el-select>
      </FormItem>
      <FormItem prop="department" v-if="!invitationToken && curIdentity != 4">
        <i-input v-model="form.department"  v-if="curIdentity == '0' || curIdentity == '1'" class="borerB" clearable size="large" placeholder="请输入所在院系（选填）">
        </i-input>
        <i-input v-model="form.department" v-if="curIdentity == '2'"  class="borerB" clearable size="large" placeholder="请填写所在部门（选填）">
        </i-input>
        <i-input v-model="form.department" v-if="curIdentity == '3'" class="borerB" clearable size="large" placeholder="请填写职位（选填）">
        </i-input>
      </FormItem>

      <FormItem prop="read" class="read-tip">
        <Row type="flex" justify="space-between">
          <Col>
            <Checkbox v-model="form.read" style="line-height: 16px">
              <span style="cursor:pointer"
              >授权登录视为您已同意<span style="color:rgba(74, 121, 255, 1)">《用户协议及隐私协议》</span></span>
            </Checkbox>
          </Col>
        </Row>
      </FormItem>
      <div style="text-align: center">
        <Button @click="handleSubmit" class="submit-btn" type="primary" size="large" :loading="loading">登录
        </Button>
      </div>

    </Form>
  </div>

</template>

<script>
import Cookies from 'js-cookie';
import util from '@/utils/tools.js';
import {tokenName} from '@/router/init.js'
export default {

  name: "teacherWxForm.vue",
  data(){
    const validateCompany = (rule, value, callback) => {
      if (!value && this.curIdentity == '3') {
        return callback(new Error('请输入组织全称'));
      }else{
        callback();
      }
    };
    const validateJoinSiteId = (rule, value, callback) => {
      if (!value && (this.curIdentity == '0' || this.curIdentity == '1' || this.curIdentity == '2') ) {
        return callback(new Error(this.identityList[+this.curIdentity].tip));
      }else{
        callback();
      }
    }
    const validateNickname = (rule, value, callback) => {
      if (!value && !this.isCompany) {
        return callback(new Error('请输入名称'));
      }else{
        callback();
      }
    };
    const validateMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register_input_mobile_empty')));
      }
      if (value.length > 11) {
        return callback(new Error(this.$t('register_input_mobile_limit_max')));
      }
      if (value.length < 11) {
        return callback(new Error(this.$t('register_input_mobile_limit_min')));
      }
      callback();
    };

    const validateMobileCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('register_input_mobile_code')));
      }
      if (!util.captchaCodeCheck(value,this.mobileHash)) {
        return callback(new Error('短信验证码不正确'));
      }
      callback();
    };
    const validateRead = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('read_check')));
      }
      callback();
    };

    return {
      form: {
        company:'',
        // realname:'',
        nickname:'',
        mobile: '',
        // captcha: '',
        mobileCode:'',
        joinSiteId:'',
        department:'',
        read:true,
      },
      rules: {
        company: [
          {validator: validateCompany, trigger: 'blur'}
        ],
        joinSiteId: [
          {validator: validateJoinSiteId, trigger: 'blur'}
        ],
        // realname:[
        //   {required:true, message:'请输入真实姓名', trigger: 'blur'}
        // ],
        nickname:[
          {validator: validateNickname, trigger: 'blur'}
        ],
        mobile: [
          {validator: validateMobile, trigger: 'blur'}
        ],
        mobileCode: [
          {validator: validateMobileCode, trigger: 'blur'}
        ],
        read: [
          {validator: validateRead, trigger: 'blur'}
        ],
      },
      captchaKey:'',
      mobileCodeStatus:true,
      captchaUrl:'',
      captchaHash:'',
      loading:false,
      mobileHash:'',
      sec:60,
      companyStatus:false,
      identityList:[
        {
          name:'自主学习',
          id:'4',
          tip:'',
        },
        {
          name:'院校学生',
          id:'0',
          tip:'请选择所在的院校',
        },
        {
          name:'院校老师',
          id:'1',
          tip:'请选择所在的院校',
        },
        {
          name:'企业员工',
          id:'2',
          tip:'请选择所在企业名称',
        },
        {
          name:'渠道商',
          id:'3',
          tip:'请输入组织全称',
        },
      ],
      curIdentity:'4',
      siteList:[],
      timer:null,
      inviteInfo:{},
    }
  },
  props:{
    wxOpenId:{
      default:'',
      type:String,
    },
    unionid:{
      default:'',
      type:String,
    },
    appid:{
      default:'',
      type:String,
    },
    isCompany:{
      default:false,
      type:Boolean,
    },
    invitationToken:{
      default:'',
      type:String,
    }
  },
  watch:{
    invitationToken:{
      handler(newV){
        console.log(newV,'newV')
        if(newV){
          this.checkInvitationToken(newV);
        }
      },
      immediate:true

    }
  },
  created(){
    //自动加载验证码
    this.getCaptcha();
  },
  methods:{
    selectIdentity(data){ //切换身份
      this.curIdentity = data.id;
      this.form.joinSiteId = '';
      this.form.company = '';
      this.form.department = '';
      // this.form.realname = '';
    },
    getSiteList(data){
      let params = {
        op:'searchSite',
        site_name:data
      };
      this.api.index.indexBinding(params).then((res)=>{
        // console.log(params,'params')
        this.siteList = res.siteList;

      })
    },
    remoteSite (query) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.getSiteList(query);
        this.timer = null
      }, 500)

    },
    //注册-邀请链接-检测租户
    checkInvitationToken(data){
      let params = {
        op:'checkInvitationToken',
        invitationToken:data,
      };
      this.api.index.indexBinding(params).then((res)=>{
        this.form.company = res.site.name;
        this.inviteInfo = res;
        this.companyStatus = true;
      }).catch((err)=>{
        this.$emit('checkInvitationFail')
      });
    },
    //获取图片验证码
    getCaptcha(){
      let params = {
        regenerate:1
      };
      this.api.index.getCaptcha(params).then((res)=>{
        this.captchaUrl = res.bin;
        this.captchaKey = res.key;
        this.captchaHash = res.hash;
      })
    },
    getMobileCode(){
      if(!this.form.mobile){
        this.$Message.warning(this.$t('login_mobild_user'));
        return;
      }
      // if(!this.form.captcha){
      //   this.$Message.warning(this.$t('login_captcha_tip'));
      //   return;
      // }
      // if(!util.captchaCodeCheck(this.form.captcha,this.captchaHash)){
      //   this.$Message.warning(this.$t('login_captcha_tip'));
      //   return;
      // }
      let data = {
        op:'sendMobile',
        mobile: this.form.mobile,
        // verifyCode: this.form.captcha,
        // verifyCodeKey:this.captchaKey,
      };
      this.api.index.indexBinding(data).then((res)=>{
        this.mobileCodeStatus = false;
        this.mobileHash = res.hash;
        let timer = setInterval(()=>{
          if(this.sec == 0){
            clearInterval(timer);
            this.sec = 60;
            this.mobileCodeStatus = true;
          }else{
            this.sec--;
          }
        },1000)
      }).catch((err)=>{
        this.mobileCodeStatus = false;
        this.mobileHash = '';
        this.sec = 15;
        let timer = setInterval(()=>{
          if(this.sec == 0){
            clearInterval(timer);
            this.sec = 60;
            this.mobileCodeStatus = true;
          }else{
            this.sec--;
          }
        },1000)
      });

    },
    handleSubmit() {
      if (this.loading) {
        return;
      }
      console.log(isNaN(+this.form.joinSiteId),'dddd')


      this.$refs.registerForm.validate((valid) => {
        if (!valid) {
          this.loading = false;
          return;
        }
        this.loading = true;

        let data = {
          // company: this.form.company,
          nickname:this.form.nickname,
          // realname:this.form.realname,
          mobile: this.form.mobile,
          mobileCode:this.form.mobileCode,
          appid:this.appid,
          openid:this.wxOpenId,
          unionid:this.unionid,
          op:'submit',

        }


        if(this.invitationToken){ //邀请码注册
          data.invitationToken = this.invitationToken;
        }else{
          // if(!this.isCompany){
          //   data.joinDefaultSite = 1;
          // }else{
          //
          //   data.joinDefaultSite = 0;
          //   data.createSite = 1;
          // }
          data.roleKey = this.curIdentity;


          // if (this.site.id) {
          //   data.joinSiteId = this.site.id;
          // }
          // if (this.company) {
          //   data.company = this.company;
          //   data.joinSiteId = '0';
          //   data.createSite = 1;
          // }
          // if (this.department) {
          //   data.department = this.department
          // }

          if(isNaN(+this.form.joinSiteId)){
            data.company = this.form.joinSiteId;
            data.joinSiteId = '0';
            data.createSite = 1;
          }else{
            data.joinSiteId = this.form.joinSiteId;
          }
          if(this.form.company){
            data.company = this.form.company
            data.joinSiteId = '0';
            data.createSite = 1;
          }
          if(this.form.department){
            data.department = this.form.department
          }
        }
        // if(this.$route.query.from == 'website'){  //从官网来的 注册为老师
        //   data.isTeacher = 1;
        // }
        if(this.curIdentity == 4){
          data.joinDefaultSite = '1';
        }

        this.api.index.indexBinding(data).then((res)=>{
          this.loading = false;
          this.$store.commit('login', {
            accessToken: res.access_token,
            userName: this.form.mobile
          });

          //是否登录的标志
          // Cookies.set('liteAccessToken', res.access_token);
          let domain = util.getDomain();
          //是否登录的标志
          // Cookies.set('liteAccessToken', res.access_token);
          //添加ai问答token

          Cookies.set(tokenName, res.access_token,{domain});
          util.setCookie('liteLoginAccount', this.form.mobile, 30);

          this.$store.commit('setLoginStatus', true);
          this.$emit('success');
        }).catch((err)=>{
          this.loading = false;
        });
      });
    },
  }
}
</script>

<style scoped lang="scss">
.get-code{
  width: 100%;
  height: 34px;
  background: #4A79FF;
  border-radius: 4px;
  color:#FFFFFF;
  font-size: 14px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
}
.get-code-not{
  width: 100%;
  border: 1px solid #c3cbd6;
  color:#c3cbd6;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}
.message{
  cursor: pointer;
}
.select-identity{
  color: #333333;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;

  .select-identity-list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >div{
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      >img{
        margin-right: 4px;
      }
    }
  }
}
.submit-btn{
  margin: 0 auto;
  width: 200px;
  height: 40px;
  background: #4A79FF;
  border-radius: 4px;
}
</style>
